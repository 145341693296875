html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#FFFFFF";
  height: 100%;
  /* overflow: hidden; */
}

code {
  font-family: "Cairo", sans-serif;
}

p {
  font-family: "Cairo", sans-serif;
}
#root {
  height: 100%;
}

@media print {
  body{
    direction: rtl;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition : "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
